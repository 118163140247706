/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "The only thing more difficult than expanding an occupied hospital is expanding it vertically. But Robins & Morton did just that, and in late 2022, they celebrated opening the four-story vertical expansion of Joe DiMaggio Children’s Hospital in Hollywood, Florida, alongside Memorial Healthcare System.")), "\n", React.createElement(_components.p, null, "The three-year project spanned nearly 165,000 square feet and added 72 new patient rooms, two cardiac hybrid operating rooms, two surgical suites and an intraoperative MRI system (IMRIS)."), "\n", React.createElement(_components.p, null, "The IMRIS is an especially impressive technology as it is used in spaces that function as both hybrid imaging and operating suites. To install it, Robins & Morton had to completely demolish the existing administration area on the hospital’s second floor and extend the deck by 15 feet. This required a new exterior wall and a large, steel structure to hang the IMRIS from the ceiling."), "\n", React.createElement(_components.p, null, "Building on the hospital’s theme, The Power of Play, the expansion also features several play zones and family support areas with amenities such as a fitness center, spa and family dining area. As a respite space, the fifth floor includes an elevated outdoor terrace for patients and families. With the addition of the four floors, the completed hospital now stands eight stories."), "\n", React.createElement(_components.p, null, "“Healthcare construction is rewarding in that we know, when we leave, there will be more care available to the community,” Robins & Morton Vice President Bruce Adams said. “But there’s something even more special about providing these important upgrades for the care of children.”"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
